import dom from '../../wrapper/DomWrapper';

const TAGS = [
  'description',
  'og:title',
  'og:description',
  'twitter:title',
  'twitter:description',
];

/**
 *
 * @param {string=} title
 * @param {string=} description
 */
const updateTitleDescription = ({ title, description }) => {
  const elTitle = dom.getElement('title');
  const elHead = dom.document.head;

  if (title) {
    if (elTitle) {
      elTitle.textContent = title;
    } else {
      elHead.insertAdjacentHTML('beforeend', `<title>${title}</title>`);
    }
  }

  TAGS.forEach((name) => {
    const isTitle = name.includes('title');
    const isDescription = name.includes('description');

    if (!isTitle && !isDescription) return;

    const content = isTitle ? title : description;

    if (!content) return;

    const key = name === 'description' ? 'name' : 'property';
    const elMeta = dom.getElement(`meta[${key}="${name}"]`);

    if (elMeta) {
      elMeta.setAttribute('content', content);
    } else {
      elHead.insertAdjacentHTML('beforeend', `<meta ${key}="${name}" content="${content}"></meta>`);
    }
  });
};

export default updateTitleDescription;
